$primary: #54634b;
$secondry: #f2e1ce;
$inputColor: #f2e1ce33;
$inputBorder: rgba(53, 35, 41, 0.3);
$white: #ffffff;
$body: #fcf9f5;
$bold: 600;
$sucessColor: #87a376;

body {
  font-family: "avenir_regularregular";
  font-weight: normal;
  font-size: 16px;
  color: $primary;
  background: $body;
}

.login_btn {
  background: $primary;
  padding: 14px;
  border-radius: 10px;
  font-weight: $bold;
  letter-spacing: 2px;

  &:hover {
    border: solid 1px $primary;
    color: $primary;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.container,
.container-sm {
  max-width: 100%;
}

.white_bg {
  background-color: $white;
}
.btn-outline-success {
  background: $primary;
  border-color: $primary;
  color: $white;
  min-width: 90px;
  font-weight: 800;
  justify-content: center;
  &:hover {
    background: transparent;
    color: $primary !important;
    border: solid 1px $primary;
  }
}
.btn-outline-primary {
  border-color: $primary;
  color: $primary;
  min-width: 90px;
  font-weight: 800;
  &:hover {
    border-color: $primary;
    background-color: $primary;
    color: $white;
  }
  &:active {
    background-color: $primary;
    color: $white;
  }
  &:first-child:active {
    border-color: $primary;
    background-color: transparent;
    color: $primary;
  }
}

.btn-outline-success:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: transparent;
  border-color: $primary;
  color: $primary;
}
.btn-outline-danger {
  padding: 12px 20px;
}

.btn {
  &.cstm-btn-close {
    background-color: #ebeae4;
    color: $primary;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 800;
    border: 1px solid #ebeae4;
    &:hover {
      border-color: $primary;
    }
  }
}

.headerBtn {
}
.header_dropdown {
  background-position: right 0.15rem center;
  option {
    background: $secondry;
    color: $primary;
  }
}
/* scroll bar */

.sidebar_menu::-webkit-scrollbar-track {
  background-color: #f2e1ce;
}

.sidebar_menu::-webkit-scrollbar-thumb {
  background: #e9b478;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e2e1ce;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #54634b;
}
/* scroll bar */
// login page

.login_page_bg {
  .btn {
    padding: 14px;
  }
}

.form-control {
  padding: 16px;
  font-weight: 800;
  color: $primary;
  border-radius: 10px;
  border: 0;
  border: solid 1px $inputBorder;

  &:focus {
    border: solid 1px $inputBorder;
    color: $primary;
  }
}

.form-control-opening {
  padding: 18px;
  font-weight: 800;
  color: $primary;
  border-radius: 7px;
  border: 0;
  height: 10px;
  border: solid 1px $inputBorder;

  &:focus {
    border: solid 1px $inputBorder;
    color: $primary;
  }
}

select {
  &.form-select {
    background-color: $inputColor;
    padding: 14px;
    border-color: $inputBorder;
    color: $primary;
    font-weight: 800;
    min-width: 80px;
    & option {
      background-color: $secondry;
    }
    // &:focus {
    //   border: solid 1px $inputBorder;
    // }
  }
}
.form-label {
  color: $primary;
  font-weight: $bold;
}

input {
  border-color: $inputBorder;
  &[type="text"] {
    background-color: $inputColor;
  }
  &[type="password"] {
    background-color: $inputColor;
  }
  &[type="date"] {
    background-color: $inputColor;
  }
  &[type="time"] {
    background-color: $inputColor;
  }
  &[type="number"] {
    background-color: $inputColor;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.form-check-input {
  border: 1px solid $primary;
}

textarea.form-control {
  background-color: $inputColor;
  border-color: $inputBorder;
}
.theme_color {
  color: $primary;
}

// Select Input Design CSS START
.cstm-input-wrapper {
  position: relative;
  user-select: none;
  .cstm-select-input {
    position: absolute;
    opacity: 0;
    &:checked {
      & + .cstm-select-label {
        background-color: $primary;

        &::after {
          content: "\F272";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 26px;
          font-weight: 500;
          font-family: "bootstrap-icons";
          color: $white;
          background-color: $primary;
          text-align: center;
          border-radius: 6px;
        }
      }
    }
  }
  .cstm-select-label {
    position: relative;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 800;
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 6px;
    cursor: pointer;
  }
}
.multi-select {
  & [class*="-control"] {
    padding: 3px;
    border-color: $inputBorder;
    background-color: $inputColor;
    border-radius: 10px;
    min-height: 54px;
    box-shadow: none;
    &:hover {
      border-color: $inputBorder;
    }

    [class*="-multiValue"] {
      background-color: $primary;
      color: $body;
      padding: 4px;
      font-size: 16px;
      border-radius: 5px;
      [class*="-MultiValueGeneric"] {
        color: $body;
        font-size: 16px;
      }
    }
  }
}
// Select Input Design CSS END
/* login page /*/

.login_page_card {
  background: $white;
  border-radius: 10px;
  max-width: 612px;
  width: 100%;
  padding: 80px;

  .login_page_logo {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  p {
    font-weight: $bold;
  }
}

.forgotPassword {
  text-align: right;
  padding-right: 14px;
  margin: auto;
  cursor: pointer;

  .link {
    text-decoration: none;
    color: $primary;
  }
}

/* forgot password */

.forgot_page_card {
  background: $white;
  border-radius: 10px;
  min-width: 612px;
  padding: 60px;

  .forgot_page_logo {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  p {
    font-weight: $bold;
  }
}

/* table  start*/
.tableWrap {
  .table-container {
    height: calc(100vh - 21rem);
    overflow-y: auto;

    table {
      thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
      }

      tr {
        height: 51px;
        vertical-align: top;
        color: $primary;

        th {
          font-size: 16px;
          font-weight: 800;
          line-height: 24.99px;
          white-space: nowrap;

          input {
            padding: 8px !important;
            background-color: $white;
            border: 1px solid #ebeae4;
            color: $primary;
            margin: 0.5rem 0 1rem 0;
            &[type="checkbox"] {
              width: 20px;
            }
            &:not([type="checkbox"]) {
              min-width: 60px;
            }
          }

          select {
            padding: 8px !important;
            background-color: $white;
            border: 1px solid #ebeae4;
            color: $primary;
            min-width: 120px;
            font-weight: 800;
            margin: 0.5rem 0 1rem 0;
          }
        }

        td {
          font-size: 14px;
          font-weight: 800;
          line-height: 21.87px;
          vertical-align: middle;
        }
      }
    }
  }
}
.coupon-category-table {
  table {
    tbody {
      tr {
        td {
          b {
            font-size: clamp(16px, 4vw, 20px);
            font-weight: 800;
          }
        }
      }
    }
  }
}
table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
.table_pagination {
  width: 165px;
  color: #87a376;
  font-size: 16px;
}

[class*="round_circle_"] {
  height: 22px;
  width: 22px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 800;
  border-radius: 14px;
  text-align: center;
  background-color: #ebeae4;
  color: #000000;
  cursor: pointer;
}

.round_circle_green {
  background-color: #54634b;
  color: white;
}

.status_icon {
  // display: ruby-text;
  color: $primary;
}
.status_icon_deactive {
  // display: ruby-text;
  color: red;
}

.bestsellers-table-card {
  table {
    margin-top: 15px;
    margin-bottom: 0;
    thead {
      th {
        background-color: rgba(235, 234, 228, 0.4);
        color: $primary;
        font-weight: 400;
        border-bottom: none;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
  }
}
.trending-product-card {
  .card-title {
    & + span {
      font-size: 12px;
    }
  }
  .tableWrap {
    margin-top: 15px;
    div:has(:empty) {
      tr {
        height: 0;
      }
      th {
        display: none;
      }
    }
    table {
      tr {
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
  }
}

/* table  end*/

// top nav bar

.topNav {
  nav {
    background: $secondry;
    padding: 15px 5px;
    .dropdown-menu {
      background-color: $secondry;
      .dropdown-item {
        &:hover {
          background-color: $primary;
          color: $white;
        }
        &.active {
          color: $white;
          background-color: $primary;
        }
      }
    }
    form {
      width: 40%;
      max-width: 500px;
    }

    .searchWrap {
      background: $body;
      border-radius: 10px;
      width: 100%;
      position: relative;

      input {
        padding: 12px 20px 12px 50px;
        background: $body;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.7;
        color: $primary;
        &::placeholder {
          color: $primary;
        }
      }

      svg {
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// side bar
.menu_heading {
  text-decoration: none;
  color: $secondry;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 10px;
  display: block;
}
.menu {
  text-decoration: none;
  color: $white;
  font-weight: 800;
  line-height: 26px;
  width: 100%;
  height: 56px;
  align-items: center;
  font-size: 16px;
  padding: 5px 15px;
  letter-spacing: 1.1px;
}

.menu_icons {
  height: 24px;
  width: 24px;
  font-weight: 800;
  text-align-last: center;
  margin-left: 10px;
  color: white;
  font-size: 18px;
}

.false {
  .menu {
    color: $secondry;

    svg {
      * {
        stroke: $secondry;
      }
    }
  }
}

.active_tab {
  background-color: $secondry;
  border-radius: 8px;
  color: $primary;

  .menu {
    color: $primary;

    svg {
      * {
        stroke: $primary;
      }
    }
  }
}

.sidebar_open {
  background-color: #54634b;
  padding: 30px 0;
  width: 300px;
  transition: all 0.3s ease-in-out;
  ul {
    padding: 0 30px;
  }
}
.fix-content {
  height: calc(100vh - 0.5rem);
  max-height: calc(100vh - 0.5rem);
  overflow-y: auto;
  width: calc(100% - 300px);
  padding-left: 12px;
}
.sidebar_close {
  background-color: #54634b;
  padding: 30px 0px;
  width: 75px;
  transition: all 0.3s ease-in-out;

  & + .fix-content {
    width: calc(100% - 75px);
    overflow-x: hidden;
  }

  ul {
    padding: 0 10px;
  }
}

.sidebar-logo-wrapper {
  padding: 0 30px;
  color: white;
}

.sidebar_menu {
  height: calc(100vh - 170px);
  overflow-y: auto;

  .fix-bottom-menu {
    position: absolute;
    bottom: 8px;
    left: 15px;
    span {
      color: $secondry;
    }
  }
}
.f-small {
  font-size: 14px;
}

// Category Page CSS START
.heading-title {
  h4 {
    font-size: clamp(16px, 2.5vw, 22px);
  }
}

// Category Page CSS END

// Order Page CSS START
.opacity-80 {
  opacity: 0.8;
}
.order-table {
  table {
    min-width: 400px;
    .table-order-img-wrapper {
      position: relative;
      width: 90px;
      padding: 12px 12px 0 0;
      img {
        width: 100%;
      }
      .order_img_badge {
        position: absolute;
        top: 0;
        right: 0;
        height: 22px;
        width: 22px;
        font-size: 12px;
        line-height: 22px;
        background-color: $sucessColor;
        color: $white;
        border-radius: 50%;
      }
    }
  }
}

.order-radio-group {
  label {
    background-color: rgba(235, 234, 228, 0.5);
    position: relative;
    padding: 8px 15px;
    padding-left: 30px;
    font-weight: 800;
    border: none;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 10px;
      width: 12px;
      height: 12px;
      border: 2.8px solid $primary;
      background-color: transparent;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      margin-left: 15px;
    }
  }
  input {
    &.btn-check {
      &:checked {
        & + label {
          background-color: rgba(135, 163, 118, 0.5);

          &::before {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.stepper {
  list-style: none;
  display: flex;
  align-items: center;

  .node {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #54634b;
    margin-right: 10px;
    position: relative; /* Make the node container relative */

    &.green {
      &::after {
        content: "";
        width: 2px;
        height: calc(
          100% + 120px
        ); /* Adjust the height to cover the content area */
        background-color: #54634b;
        position: absolute;
        bottom: -20px; /* Position the line below the content area */
        left: 50%;
        transform: translateX(-50%);
      }
    }
    & + .content {
      position: relative;
      margin-left: 20px;
      width: 100%;
      .time-line-text {
        p {
          font-size: 14px;
          font-weight: 800;
        }
      }
    }
  }
}

.order_details_heading {
  font-weight: 800;
  font-size: 25px;
  line-height: 26px;
}
table {
  .order_details_credit_card {
    font-weight: 800;
    font-size: 16px;
    line-height: 24.99px;
    color: #54634b;
    padding: 5px;
  }
  tbody {
    tr {
      &:last-child {
        .order_details_credit_card {
          padding-bottom: 25px;
        }
      }
    }
  }
  tfoot {
    tr {
      .order_details_credit_card {
        padding-top: 25px;
      }
    }
  }
}

.order_customer {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: #54634b;
  opacity: 0.8;
}

.order_img_badge {
  height: 22px;
  width: 22px;
  color: white;
  div {
    display: flex;
    justify-content: space-around;
  }
}

.pagination {
  .page-item {
    font-weight: 800;
    line-height: 24px;
  }
  .form-control {
    font-weight: 800;
    padding: 10px 5px;
    text-align: center;
    min-width: 70px;
  }
}

// Order Page CSS END

// customer details page css start

.customer_details_table {
  .card {
    border-color: rgba(235, 234, 228, 1);
  }

  .active {
    background-color: $primary !important;
    border: $primary;
  }

  .user_details {
    .prfile {
      width: 69px;
      height: 69px;
      border-radius: 40px;
    }
    .user_details_section {
      border-right: 1px solid rgb(210 210 210);
    }
  }

  .filter_customer {
    form {
      width: 100%;
      max-width: 330px;
    }

    .filter_wrap {
      background: $body;
      border-radius: 10px;
      width: 100%;
      position: relative;

      .input-group {
        position: relative; /* Added */
      }

      input {
        background: $body;
        width: calc(100% - 30px); /* Adjusted width to accommodate icon */
        font-size: 16px;
        padding-left: 40px; /* Added padding to the left for icon */
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }

      svg {
        position: absolute;
        left: 10px; /* Adjusted left position */
        top: 50%;
        transform: translateY(-50%);
        z-index: 1; /* Added to ensure the icon appears above the input */
      }
    }

    .form-select {
      background-color: $inputColor;
    }
  }

  table {
    .open_sub_container {
      background-color: rgba(235, 234, 228, 0.4);
    }
    .open_sub_container_content {
      background-color: rgba(235, 234, 228, 0.2);
    }
    tr {
      height: 51px;
      vertical-align: middle;
      color: $primary;

      th {
        font-size: 13px;
        font-weight: 400;
        line-height: 20.08px;
        white-space: nowrap;
      }
      td {
        font-size: 15px;
        font-weight: 800;
        line-height: 23.43px;
        vertical-align: middle;
        p {
          font-size: 13px;
          font-weight: 400;
          line-height: 20.31px;
        }
      }
    }
  }
}
// customer details page css end

//menumanager  page css start
.menumanagerwraper {
  .filter_wrap {
    background: $body;
    border-radius: 10px;
    width: 100%;
    position: relative;

    input {
      padding: 12px 20px 12px 50px;
      background: $body;
      width: 100%;
      font-size: 16px;
      line-height: 1.7;
    }

    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .nav-tabs {
    border: 1px solid $secondry;
    border-radius: 8px;
    background-color: white;

    .nav-link {
      border: none;
      color: $primary;
      border-bottom: 1px solid transparent;
      font-weight: 800;
    }

    .nav-link.active {
      border-color: $primary;
      border-bottom-width: 4px;
    }
  }

  .card {
    height: calc(100vh - 13rem);
    padding: 14px;
    overflow-y: scroll;
  }
}

//menumanager page css end

//overview wraper

.overview_wraper {
  form {
    width: 40%;
    max-width: 500px;
  }

  .filter_wrap {
    background: $body;
    border-radius: 10px;
    width: 100%;
    position: relative;

    input {
      padding: 12px 20px 12px 50px;
      background: $body;
      width: 100%;
      font-size: 16px;
      line-height: 1.7;
      height: 60px;
      border-radius: 6px;
    }

    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .accordion_header {
    display: flex;
    margin-right: 30px;
    gap: 60px;
    color: $primary;
  }
  .accordion-button:focus {
    border-bottom: 0px;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  .content {
    margin-left: 60px;
  }

  table {
    tr {
      vertical-align: middle;
      color: $primary;

      th {
        p {
          padding: 0px;
          margin: 0px;
        }
      }
      td {
        vertical-align: middle;
        p {
          padding: 0px;
          margin: 0px;
          &.col-min-w-170 {
            min-width: 170px;
          }
        }

        div {
          padding: 4px;
          background-color: $secondry;
          justify-content: center;
          display: flex;
        }
      }
    }
  }
}
//overview wraper

//menu_manager_menu_wraper
.menu_manager_menu_wraper {
  .nav-tabs {
    border: 1px solid $secondry;
    border-radius: 8px;
    background-color: white;

    .nav-link {
      border: none;
      color: $primary;
      border-bottom: 1px solid transparent;
      font-weight: 800;
    }

    .nav-link.active {
      border-color: $primary;
      border-bottom-width: 4px;
    }
  }

  .card {
    height: calc(100vh - 13rem);
    padding: 14px;
    overflow-y: scroll;
  }

  .filter_wrap {
    background: $body;
    border-radius: 10px;
    width: 100%;
    max-width: 330px;
    position: relative;

    input {
      padding: 12px 20px 12px 50px;
      background: $body;
      width: 1005;
      font-size: 16px;
      line-height: 1.7;
      height: 60px;
      border-radius: 6px;
    }

    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  table {
    tr {
      vertical-align: middle;
      color: $primary !important;

      td {
        vertical-align: middle;
        font-weight: 800;
        &:not(:first-child) {
          min-width: 170px;
        }
      }
    }
  }
}

//menu_categories_wraper start

.menu_categories_wraper {
  .filter_wrap {
    background: $body;
    border-radius: 10px;
    width: 100%;
    max-width: 330px;
    position: relative;

    input {
      padding: 12px 20px 12px 50px;
      background: $body;
      width: 1005;
      font-size: 16px;
      line-height: 1.7;
      height: 60px;
      border-radius: 6px;
    }

    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.menu_new_categories_wraper {
  .add_note {
    gap: 8px;
    justify-content: end;
  }
}

.menu_wraper {
  .add_note {
    gap: 8px;
    justify-content: end;
  }
}

//menu_manager_menu_wraper end

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
  background-color: $inputColor;
  color: $primary;
}
.ql-container.ql-snow {
  background-color: $inputColor;
  border-radius: 0 0 8px 8px;
  height: 80px;
}

.spineer {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

//menu_item_wraper start
.menu_item_wrapper {
  .filter_wrap {
    background: $body;
    border-radius: 10px;
    width: 100%;
    position: relative;

    input {
      padding: 12px 20px 12px 50px;
      background: $body;
      width: 100%;
      font-size: 16px;
      line-height: 1.7;
      height: auto;
    }

    svg {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
//menu_item_wraper end

.country-code-select {
  max-width: 80px !important;
}

//toast start
.toast {
  width: auto !important;
}

.toast-content {
  padding: 15px;
}

.icon i {
  font-size: 35px;
}

.toast.success {
  border: 2px solid #14a44d !important;
  color: #14a44d;
}

.toast.warning {
  border: 2px solid #f9a02c !important;
  color: #f9a02c;
}

.toast.erro {
  border: 2px solid #d90429 !important;
  color: #d90429;
}
//toast end
.left-toast {
  position: fixed;
  top: calc(50% - 209px); /* Adjust the top position as needed */
  right: 20px; /* Adjust the left position as needed */
  transform: translateY(-50%);
}
.form-top-sticky {
  padding: 5px 0 15px 0;
  background: #fcf9f5;
  position: sticky;
  top: 110px;
  z-index: 999;
}
.form-top-sticky::before {
  content: "";
  position: absolute;
  top: -30px;
  height: 30px;
  width: 100%;
  background: #fcf9f5;
}

.shimmer-cell {
  flex: 1;
  height: 20px; /* Set the height of each cell */
  margin: 8px;
  background: linear-gradient(to right, #f6f7f8 0%, #e8e8e8 50%, #f6f7f8 100%);
  background-size: 200% auto;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.image-container-preview {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

//tags css
.rti--input {
  width: 100%;
  padding-left: 16px;
  min-height: 56px;
}
.rti--container {
  background-color: $inputColor;
  padding: 0px;
}
.rti--tag {
  padding-top: 6px;
  padding-left: 16px;
}
//tags css

.form-select-cstm [class*="-control"] {
  border: 1px solid #bfb5b1 !important;
  height: auto;
  min-height: 56px;
  border-radius: 10px;
  background: #f8f0e7;
  box-shadow: none;
}
.form-select-cstm [class*="-control"] [class*="-ValueContainer"] {
  padding-left: 16px;
}
select.form-select.product-filter-select {
  padding: 0.3rem 0.5rem !important;
  height: 100%;
}

// add update time form blocker css  start

/* CSS for the overlay */
/* CSS for the overlay */
.form-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  gap: 20px;
  flex-direction: column;
}

/* CSS for the loader */
.form-blocker .spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.4em;
  border-color: rgba(255, 255, 255, 0.6) transparent rgba(255, 255, 255, 0.6)
    transparent;
  animation: spin 1s linear infinite;
}

/* CSS for the message */
.form-blocker .message {
  color: #fff;
  font-size: 18px; /* Slightly larger font size */
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// add update time form blocker css  end

//tooltip
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  white-space: nowrap; /* Prevents the text from wrapping */
  background-color: #333; /* Tooltip background color */
  color: #fff; /* Tooltip text color */
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px; /* Adjust padding as needed */
  position: absolute;
  top: -140%;
  left: -120%; /* Centers the tooltip relative to the container */
  transform: translateX(-50%); /* Centers the tooltip horizontally */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  max-width: 200px; /* Optional: limits the max width of the tooltip */
  word-break: break-word; /* Allows text to break if too long */
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

//tooltip

.react-datepicker__close-icon::after {
  background-color: $primary;
  line-height: 13px;
}

.force-close-custom-title {
  font-size: 18px; /* Change the size as needed */
}

.active-drop-zone {
  border-color: #4caf50; /* Change the border color on drag */
}

.react-datepicker-popper {
  line-height: 0;
  z-index: 2 !important;
}
